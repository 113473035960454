<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.17593 5.99999L10.7593 2.42499C10.9162 2.26807 11.0043 2.05524 11.0043 1.83333C11.0043 1.61141 10.9162 1.39858 10.7593 1.24166C10.6023 1.08474 10.3895 0.996582 10.1676 0.996582C9.94567 0.996582 9.73285 1.08474 9.57593 1.24166L6.00093 4.82499L2.42593 1.24166C2.26901 1.08474 2.05618 0.996582 1.83426 0.996582C1.61234 0.996582 1.39951 1.08474 1.24259 1.24166C1.08567 1.39858 0.997516 1.61141 0.997516 1.83333C0.997516 2.05524 1.08567 2.26807 1.24259 2.42499L4.82593 5.99999L1.24259 9.57499C1.16449 9.65246 1.10249 9.74463 1.06018 9.84618C1.01788 9.94773 0.996094 10.0566 0.996094 10.1667C0.996094 10.2767 1.01788 10.3856 1.06018 10.4871C1.10249 10.5887 1.16449 10.6809 1.24259 10.7583C1.32006 10.8364 1.41223 10.8984 1.51378 10.9407C1.61533 10.983 1.72425 11.0048 1.83426 11.0048C1.94427 11.0048 2.05319 10.983 2.15474 10.9407C2.25629 10.8984 2.34846 10.8364 2.42593 10.7583L6.00093 7.17499L9.57593 10.7583C9.6534 10.8364 9.74556 10.8984 9.84711 10.9407C9.94866 10.983 10.0576 11.0048 10.1676 11.0048C10.2776 11.0048 10.3865 10.983 10.4881 10.9407C10.5896 10.8984 10.6818 10.8364 10.7593 10.7583C10.8374 10.6809 10.8994 10.5887 10.9417 10.4871C10.984 10.3856 11.0058 10.2767 11.0058 10.1667C11.0058 10.0566 10.984 9.94773 10.9417 9.84618C10.8994 9.74463 10.8374 9.65246 10.7593 9.57499L7.17593 5.99999Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>