<template>
    <div class="clock">
        <div class="container-clock">
            <Clock />
            <p class="vclock">
                {{ formatarHoraISO(clock.date) }}
            </p>
        </div>
        <p class="time">
            {{ StringToMinutes(clock.time) }} min
        </p>
    </div>
</template>

<script>
import { ref } from "vue";
import Clock from "../../../../assets/IconsComponent/Clock.vue"

export default {
    props: ["status", "clock"],
    components: { Clock },
    setup() {
        const getStatus = status => {
            return String(status)
        }

        const upperCase = (string) => {
            if(string === 'paciente_acompanhado'){
                string = 'Paciente acompanhado'
            }else if(string === 'acompanhando'){
                string = 'Acompanhando'
            }

            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const StringToMinutes = (str) => {
            const date = new Date(`Tue Jun 14 2022 ${str}:00 GMT-0300 (Hora padrão de Brasília)`);

            return date.getHours() * 60 + date.getMinutes();
        }

        function formatarHoraISO(dataISO) {
            const data = new Date(dataISO);
            const horas = data.getHours().toString().padStart(2, '0');
            const minutos = data.getMinutes().toString().padStart(2, '0');
            return `${horas}:${minutos}`;
        }
        
        return {
            getStatus,
            upperCase,
            StringToMinutes,
            formatarHoraISO,
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #BDBDBD;
    margin: 0;
    padding: 0;
}

p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.clock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: max-content;
    gap: 6px;
    background: #FAFAFA;
    border-radius: 100px;
    padding: 0 8px;
    height: 30px;
    .container-clock{
        display: flex;
        align-items: center;
        gap: 4px;
        border-right: 1px solid #E0E0E0;
        padding-right: 6px;
        .vclock{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #828282;
        }
    }
    .time{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #BDBDBD;
    }
}
</style>