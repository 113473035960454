<template>

    <div class="content" :style="{ marginBottom: '20px !important', marginTop: '8px !important'}">
        <Skeleton width="100%" height="60px"/> 
    </div>      
 
</template>

<script>
export default {

    setup(props) {


        return {}
    }
}
</script>

<style lang="scss" scoped>
.loader {

    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    padding: 10px;
    gap: 8px;
    margin-bottom: 22px;
    min-height: 60px;

    background: white;
    box-shadow: 0px 8px 24px rgb(61 71 102 / 8%), 0px 3px 6px rgb(61 71 102 / 6%);
    border-radius: 8px;
}
</style>